import { useEffect, useState } from "react";
import Rating from "react-rating";
// import EmptyStar from "../../images/empty-star.png"
// import FullStar from "../../images/full-star.png"
const FullStar = "https://www.respondelligent.com/assetsapp/iwtr/star-full.png"
const EmptyStar = "https://www.respondelligent.com/assetsapp/iwtr/star-empty.png"
const Stars = ({ stars, clickAction, rating, setRating, msg, setMsg, setValue, isConfirmationActive, selectedRating }) => {

    const [hoverValue, setHoverValue] = useState(0);
    const [hoverMsg, setHoverMsg] = useState('');

    const click = (value) => {
        if (!isConfirmationActive) {
            setValue(value)
        }

        if (value) {
            setRating(value)
            clickAction(value)

            return setMsg(stars?.labels[value]);
        }
    }

    useEffect(() => {
        if (!selectedRating) {
            return;
        }

        click(selectedRating);
    }, []);

    return (
        <div className='tw1-mt-3'>
            <Rating
                initialRating={rating}
                emptySymbol={
                    <div className='tw1-h-12 tw1-w-12 md:tw1-h-24 md:tw1-w-24 tw1-relative'>
                        <img
                            style={{ objectFit: 'cover', maxWidth: '100%', height: 'auto' }}
                            src={EmptyStar}
                            // layout='fill'
                            // priority={true}
                            alt='Gray colored star icon'
                        />
                    </div>
                }
                fullSymbol={
                    <div className='tw1-h-12 tw1-w-12 md:tw1-h-24 md:tw1-w-24 tw1-relative'>
                        <img style={{ maxWidth: '100%', height: 'auto' }} src={FullStar} layout='fill' alt='Gold colored star icon' />
                    </div>
                }
                onClick={(value) => {
                    click(value);
                }}
                onHover={(value) => {

                    if (value) {
                        setHoverValue(value)
                        return setHoverMsg(stars?.labels[value]);
                    }
                    setHoverValue(0)
                    setHoverMsg("");
                }}
            />
            <div className='tw1-text-center tw1-mt-4'>
                <div className='tw1-text-sm tw1-font-bold tw1-relative tw1-w-max tw1-mx-auto'>
                    {(hoverValue >= 1 || (!hoverValue && rating >= 1)) && <div className='tw1-absolute tw1--top-2 tw1--left-6'>
                        <img
                            style={{ height: 14, width: 14 }}
                            src={FullStar}
                            // height={14}
                            // width={14}
                            alt='Gold colored star icon'
                        />
                    </div>}
                    {(hoverValue >= 2 || (!hoverValue && rating >= 2)) && <div className='tw1-absolute tw1--top-4 tw1-left-4 '>
                        <img
                            style={{ height: 13, width: 13 }}
                            src={FullStar}
                            // height={12}
                            // width={12}
                            alt='Gold colored star icon'
                        />
                    </div>}
                    <div className="text-[16px] tw1-text-black"> {hoverMsg || msg}</div>
                    {(hoverValue >= 3 || (!hoverValue && rating >= 3)) && <div className='tw1-absolute tw1--top-4 tw1--right-3'>
                        <img
                            style={{ height: 12, width: 12 }}
                            src={FullStar}
                            // height={8}
                            // width={8}
                            alt='Gold colored star icon'
                        />
                    </div>}
                    {(hoverValue >= 4 || (!hoverValue && rating >= 4)) && <div className='tw1-absolute tw1--top-2 tw1--right-6'>
                        <img
                            style={{ height: 11, width: 11 }}
                            src={FullStar}
                            // height={8}
                            // width={8}
                            alt='Gold colored star icon'
                        />
                    </div>}

                    {(hoverValue >= 5 || (!hoverValue && rating >= 5)) && <div className='tw1-absolute tw1--bottom-4 tw1-right-10'>
                        <img
                            style={{ height: 10, width: 10 }}
                            src={FullStar}
                            // height={8}
                            // width={8}
                            alt='Gold colored star icon'
                        />
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default Stars;
