const Twitter = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="6 6 36 36" width="18" height="18">
          <path fill="#212121" fill-rule="evenodd"
                d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z"
                clip-rule="evenodd"></path>
          <path fill="#fff"
                d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563L28.587,32.304z"></path>
          <polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"></polygon>
          <polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"></polygon>
      </svg>
  );
};

export default Twitter;
