import { useState } from "react";
import Rating from "react-rating";
// import EmptyStar from "../../images/empty-star.png"
// import FullStar from "../../images/full-star.png"
const FullStar = "https://www.respondelligent.com/assetsapp/iwtr/star-full.png"
const EmptyStar = "https://www.respondelligent.com/assetsapp/iwtr/star-empty.png"
const SmallStars = ({ stars, category, categoriesRating, setCategoriesRating, translatedData, categoriesTranslated, smallStarsValue, setSmallStarsValue, index }) => {

    const [selectedStar, setSelectedStar] = useState(0);
    const [hoverMsg, setHoverMsg] = useState('');
    const [msg, setMsg] = useState('');

    const updateState = (name, value) => {

        let isExist = false;

        if (categoriesRating.length > 0) {
            const newState = categoriesRating.map(obj => {
                if (obj.name === name) {
                    isExist = true;
                    return { ...obj, value: value };
                }

                return obj;
            });

            setCategoriesRating(newState);
        }

        if (!isExist) {
            setCategoriesRating([...categoriesRating, { name: name, value: value }]);
        }
    };

    return (
        <>
            <h3>{categoriesTranslated ?? category}</h3>
            <div className='tw1-mt-3' style={{ textAlign: 'center' }}>
                <Rating
                    initialRating={selectedStar}
                    emptySymbol={
                        <div className='tw1-h-12 tw1-w-12 md:tw1-h-16 md:tw1-w-16 tw1-relative'>
                            <img
                                style={{ objectFit: 'cover', maxWidth: '100%', height: 'auto' }}
                                src={EmptyStar}
                                // layout='fill'
                                // priority={true}
                                alt='Gray colored star icon'
                            />
                        </div>
                    }
                    fullSymbol={
                        <div className='tw1-h-12 tw1-w-12 md:tw1-h-16 md:tw1-w-16 tw1-relative'>
                            <img style={{ maxWidth: '100%', height: 'auto' }} src={FullStar} layout='fill' alt='Gold colored star icon' />
                        </div>
                    }
                    onClick={(value) => {
                        if (value) {
                            smallStarsValue[index] = value;
                            setSmallStarsValue(smallStarsValue)
                            updateState(category, value)
                            setSelectedStar(value)
                            return setMsg(stars?.labels[value]);
                        }
                    }}
                    onHover={(value) => {
                        if (value) {
                            return setHoverMsg(stars?.labels[value]);
                        }
                        setHoverMsg("");
                    }}
                />
                <div className='tw1 tw1-mt-4' style={{ borderBottom: '1px solid #eee', marginBottom: '30px' }}>
                    <div className='tw1-text-sm tw1-font-bold tw1-relative tw1-w-max'>
                        {hoverMsg || msg || translatedData?.no_rating}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SmallStars;
