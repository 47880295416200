import Facebook from "../icons/socials/Facebook";
import Twitter from "../icons/socials/Twitter";
import Linkedin from "../icons/socials/Linkedin";
import Instagram from "../icons/socials/Instagram";
import { ReactComponent as Tiktok } from "../icons/socials/Tiktok.svg";
import { ReactComponent as Website } from "../icons/socials/Website.svg";
const Icon = ({ name }) => {
  switch (name) {
    case "facebook":
      return <Facebook />;
    case "twitter":
      return <Twitter />;
    case "linkedin":
      return <Linkedin />;
    case "instagram":
      return <Instagram />;
    case "tiktok":
      return <Tiktok width="18px" height="18px" />;
    default:
      return <Website width="18px" height="18px" />;
  }
};

const Social = ({ name, url }) => {
  return (
    <a
      className='tw1-flex tw1-items-center'
      href={url}
      target='_blank'
      rel='noreferrer noopener'
    >
      <div className='tw1-mr-2'>
        <Icon name={name} />
      </div>
      {/*<span className="tw1-hidden md:tw1-block">*/}
      {/*  Like Us on <span className='tw1-capitalize'>{name}</span>*/}
      {/*</span>*/}
    </a>
  );
};

const Socials = ({ socials }) => {
  return (
    <div className='tw1-w-full tw1-border-t tw1-bg-white tw1-border-[#f4f3f4]'>
      <div className='tw1-flex tw1-items-center tw1-justify-center tw1-gap-[60px] tw1-py-3.5 tw1-text-[10px] tw1-font-extrabold tw1-text-[#a3a3a3]'>
        {socials.map(({ name, url }) => {
          if (url) {
            return <Social name={name} url={url} key={name} />;
          }
          return <></>;
          // return <span key={name}></span>;
        })}
      </div>
    </div>
  );
};

export default Socials;
